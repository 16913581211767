export function remove(arr, func) {
    let index = -1;
    for (let i = 0; i < arr.length; i++) {
        if (func(arr[i], i)) {
            index = i;
            break;
        }
    }
    if (index != -1) {
        arr.splice(index, 1);
        return true;
    } else {
        return false;
    }
}

export function multiremove(arr, func) {
    for (let i = arr.length - 1; i >= 0; i--) {
        if (func(arr[i], i)) {
            arr.splice(i, 1);
        }
    }
}