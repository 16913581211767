<template>
  <div class="accessories-row-root-container">
    <div
      class="accessories-row-cell"
      :style="{ width: showType ? '20%' : '25%' }"
    >
      <accessories-item :data-source="dataSource" />
    </div>
    <div
      class="accessories-row-cell"
      :style="{ width: showType ? '20%' : '25%' }"
    >
      <span style="font-size: 15px; font-weight: bold">{{
        dataSource.more
      }}</span>
    </div>
    <div
      class="accessories-row-cell"
      :style="{ width: showType ? '20%' : '25%', 'flex-direction': 'row' }"
    >
      <my-avatar
        :size="40"
        :name="dataSource.create_user_name"
        :img_url="dataSource.create_user_img_url"
      />
      <span style="font-size: 15px; font-weight: bold; margin-left: 20px">{{
        dataSource.create_user_name
      }}</span>
    </div>
    <div
      class="accessories-row-cell"
      :style="{ width: showType ? '20%' : '25%' }"
    >
      <span style="font-size: 14px">{{ dataSource.create_time }}</span>
    </div>
    <div
      class="accessories-row-cell"
      v-if="showType"
      :style="{ width: showType ? '20%' : '25%' }"
    >
      <span v-if="dataSource.type == 1" style="font-size: 14px">单独上传</span>
      <span v-else style="font-size: 12px"
        >来自任务：{{ dataSource.task_id }}的附件</span
      >
    </div>
  </div>
</template>

<script>
import MyAvatar from "..//MyAvatar";
import AccessoriesItem from "../Item/AccessoriesItem";
export default {
  props: {
    dataSource: Object,
    showType: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    "my-avatar": MyAvatar,
    "accessories-item": AccessoriesItem,
  },
};
</script>

<style scoped>
.accessories-row-root-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  margin: 0 5px;
  transition: all 0.2s ease;
  box-shadow: 0px 0px 1px #dddddd;
}
.accessories-row-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
  transform: translateY(-2px);
}
.accessories-row-cell {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>