<template>
  <div class="new-milestone-page-container">
    <Button type="primary" icon="ios-arrow-back" class="back-button" @click="$emit('back')"></Button>
    <div class="new-milestone-header-container">
      <div class="new-milestone-title-container" style="flex: 1">
        <span style="font-size: 32px; font-weight: bold">请查看生成的付款列表</span>
        <span style="font-size: 14px; color: #4f4e4e; margin-top: 10px">系统识别合计上传{{ payList.length }}笔交易</span>
        <span style="font-size: 14px; color: #4f4e4e; margin-top: 10px" v-if="errorList.length > 0">未通过{{
            errorList.length
          }}笔，可能的原因包括身份信息、银行卡信息不足，或者Ta不在您的联系人里</span>
        <span>
          <Poptip v-for="(item, index) in errorList" :key="index" trigger="hover" title="错误详情" :content="item.msg">
            <span style="
                display: inline-block;
                margin: 10px;
                border-radius: 4px;
                background-color: #debfbf;
                padding: 5px 10px;
              ">{{ item.client_name }}</span>
          </Poptip>
        </span>
      </div>
      <div class="new-milestone-title-container" style="align-items: flex-end">
        <fee :amount="totalAmount" :primarySize="32" :secondarySize="28" />
        <span style="font-size: 13px; font-weight: bold; margin-top: 10px">合计金额</span>
      </div>
    </div>

    <pay-list :dataSource="payList" />

    <Button size="large" type="primary" style="width: 200px; margin: 50px 0px" @click="next">保存通过的付款列表</Button>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import PayList from "../../../components/List/PayList";
import Fee from "../../../components/Fee";
import moment from "moment";
export default {
  props: {
    milestoneId: String,
    excel: Array,
  },
  components: {
    fee: Fee,
    "pay-list": PayList,
  },
  data() {
    return {
      projectId: 0,
      payList: [],
      errorList: [],
    };
  },
  mounted() {
    this.projectId = this.$router.currentRoute.params.id;

    this.milestoneGetExcelDataAction({
      employer_id: localStorage.getItem("employer_id"),
      project_id: this.projectId,
      excel: this.excel,
    })
      .then((res) => {
        this.payList = res.pay_list;
        this.errorList = res.error_list;
      })
      .catch((error) => {
        this.$Message.error(error.msg);
      });
  },
  computed: {
    totalAmount() {
      let total = 0;
      for (let item of this.payList) {
        total += item.amount;
      }
      return total;
    },
  },
  methods: {
    ...mapActions({
      milestoneGetExcelDataAction: "milestoneGetExcelData",
    }),
    next() {
      if (this.payList.length == 0) {
        this.$Message.error("至少包含一条付款");
        return;
      }
      if (this.payList.length >= 500) {
        this.$Message.error("不能大于500笔交易");
        return;
      }
      this.$emit("next");
    },
  },
};
</script>

<style scoped>
.new-milestone-page-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.new-milestone-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.new-milestone-title-container {
  display: flex;
  flex-direction: column;
}
</style>