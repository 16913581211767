<template>
  <Modal v-model="isShowModal" fullscreen footer-hide>
    <div class="new-milestone-modal-root-container">
      <milestone-step1 v-if="currentPage == 1" @next="currentPage++" />
      <milestone-step2
        @excel="updateExcel"
        v-if="currentPage == 2"
        @next="currentPage++"
        @back="currentPage--"
        :milestoneId="milestoneForm.milestone_id"
        :excel="milestoneForm.excel"
      />
      <milestone-step3
        v-if="currentPage == 3"
        @next="currentPage++"
        @back="currentPage--"
        :excel="milestoneForm.excel"
      />
      <milestone-step4
        :milestoneId="milestoneForm.milestone_id"
        :dataSource="milestoneForm"
        v-if="currentPage == 4"
        @back="currentPage--"
        @refreshInfo="refreshInfo"
      />
    </div>
    <milestone-create-confirm
      ref="milestoneCreateConfirm"
      @confirm="confirm"
      :dataSource="milestoneForm"
    />
    <milestone-create-complete ref="milestoneCreateComplete" @complete="complete" />
  </Modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import MilestoneCreateConfirm from "../Modal/MilestoneCreateConfirm";
import MilestoneCreateComplete from "../Modal/MilestoneCreateComplete";
import MilestoneStep1 from "../Page/MilestoneStep1";
import MilestoneStep2 from "../Page/MilestoneStep2";
import MilestoneStep3 from "../Page/MilestoneStep3";
import MilestoneStep4 from "../Page/MilestoneStep4";
export default {
  components: {
    "milestone-create-confirm": MilestoneCreateConfirm,
    "milestone-create-complete": MilestoneCreateComplete,
    "milestone-step1": MilestoneStep1,
    "milestone-step2": MilestoneStep2,
    "milestone-step3": MilestoneStep3,
    "milestone-step4": MilestoneStep4,
  },
  data() {
    return {
      isShowModal: false,
      projectId: 0,
      currentPage: 1,
      milestoneForm: {
        milestone_id: -1,
        excel: [],
        accessories: [],
        name: "",
        more: "",
      },
    };
  },
  mounted() {
    this.projectId = this.$router.currentRoute.params.id;
  },
  methods: {
    ...mapActions({
      milestoneAddOrUpdateAction: "milestoneAddOrUpdate",
    }),
    updateExcel(excel) {
      this.milestoneForm.excel = excel;
    },
    open(milestone_id) {
      this.milestoneForm = {
        milestone_id: milestone_id,
        excel: [],
        accessories: [],
        name: "",
        more: "",
      };
      this.currentPage = 1;
      this.isShowModal = true;
    },
    close() {
      this.isShowModal = false;
    },
    showConfirm() {
      this.$refs.milestoneCreateConfirm.open();
    },
    confirm() {
      this.$refs.milestoneCreateConfirm.close();

      let params = Object.assign(this.milestoneForm, {
        employer_id: localStorage.getItem("employer_id"),
        project_id: this.projectId,
      });

      this.milestoneAddOrUpdateAction(params)
        .then((res) => {
          this.$refs.milestoneCreateComplete.open(res.milestone_id);
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    complete() {
      this.$refs.milestoneCreateComplete.close();
      this.close();
    },
    refreshInfo(info) {
      this.milestoneForm.name = info.name;
      this.milestoneForm.more = info.more;
    },
  },
};
</script>

<style scoped>
.new-milestone-modal-root-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 50px;
  padding-left: 285px;
  padding-right: 150px;
  background-color: #f9f9f9;
  overflow: hidden;
}
</style>