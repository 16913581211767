<template>
  <div class="xls-item-root-container">
    <div class="xls-item-cell" style="width: 40%;">
      <accessories-item style="width:100%" :data-source="dataSource" />
    </div>
    <div class="xls-item-cell" style="flex-direction: row;width: 30%;">
      <my-avatar
        :size="40"
        :name="dataSource.create_user_name"
        :img_url="dataSource.create_user_img_url"
      />
      <span
        style="font-size: 15px;font-weight:bold;margin-left:20px;"
      >{{dataSource.create_user_name}}</span>
    </div>
    <div class="xls-item-cell">
      <span style="font-size: 14px;">{{dataSource.create_time}}</span>
    </div>
    <div class="xls-item-cell" style="width: 10%;">
      <Icon type="ios-trash" :size="20" @click="deleteItem" />
    </div>
  </div>
</template>

<script>
import MyAvatar from "../MyAvatar";
import AccessoriesItem from "./AccessoriesItem";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    "my-avatar": MyAvatar,
    "accessories-item": AccessoriesItem,
  },
  methods: {
    deleteItem() {
      this.$parent.$emit("deleteItem", this.dataSource);
    },
  },
};
</script>

<style scoped>
.xls-item-root-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  margin: 0 5px;
  transition: all 0.2s ease;
  box-shadow: 0px 0px 1px #dddddd;
}
.xls-item-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
  transform: translateY(-2px);
}
.xls-item-cell {
  width: 20%;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>