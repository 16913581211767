<template>
  <div class="new-milestone-page-container">
    <span style="font-size:32px;font-weight:bold;margin-bottom:20px">向里程碑添加包含了奖励的已完成任务</span>
    <div class="new-milestone-row">
      <span>没有包含了奖励的已完成任务🤔️？您还可以</span>
      <div class="new-milestone-btn" @click="()=>{$emit('next')}">
        <img :src="icons.xls" style="width:25px;height:25px" />
        <span style="margin-left:10px;">通过Excel上传付款列表</span>
      </div>
      <a :href="ossHost+'upload_template_pay.xlsx'" style="font-size:12px;color:#3483BF;">下载模板</a>
    </div>
    <tip-board
      style="margin-top:50px;"
      title="Ooh,没有查询到已完成的任务"
      sub-title="根据您输入的搜索条件，没有为您找到任何交易。请尝试更换搜索条件"
      :source="icons.notFound"
    />
  </div>
</template>

<script>
import * as icon from "../../../common/icon";
import TipBoard from "../../../components/TipBoard";
import { ossHost } from "../../../common/env";
export default {
  components: {
    "tip-board": TipBoard,
  },
  data(){
    return{
      ossHost: ossHost,
      icons:icon
    }
  }
};
</script>

<style scoped>
.new-milestone-page-container {
  display: flex;
  flex-direction: column;
}
.new-milestone-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.new-milestone-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  background-color: aliceblue;
  border-radius: 5px;
  margin: 0 10px;
  cursor: pointer;
  user-select: none;
}
</style>