<template>
  <div class="new-milestone-page-container">
    <Button type="primary" icon="ios-arrow-back" class="back-button" @click="$emit('back')"></Button>

    <span style="font-size:32px;font-weight:bold">已保存，最后命名，添加附件及备注</span>

    <my-input
      style="margin-top:50px;width:500px;"
      ref="formName"
      title="里程碑名称"
      size="large"
      v-model="dataSource.name"
      :validate="nameValidate"
    />

    <div class="new-milestone-header-container">
      <span style="font-size: 23px;font-weight:bold">全部附件{{dataSource.accessories.length}}个</span>
      <Icon @click="upload" type="ios-cloud-upload-outline" style="cursor: pointer;" :size="30" />
    </div>

    <accessories-list :dataSource="dataSource.accessories" />

    <my-textarea
      style="margin-top:50px;width:500px;margin-bottom:80px;"
      ref="formDesc"
      title="添加备注"
      size="large"
      :rows="4"
      v-model="dataSource.more"
    />

    <div class="tools-container">
      <Button @click="submit" type="primary" size="large">{{milestoneId==-1?'创建里程碑事件':'更新里程碑事件'}}</Button>
    </div>
    <accessories-drawer ref="accessoriesDrawer" @add="add" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import AccessoriesList from "../../../components/List/AccessoryList";
import AccessoriesDrawer from "../../../components/Drawer/AccessoriesDrawer";
import MyTextArea from "../../../components/Form/MyTextArea";
import MyInput from "../../../components/Form/MyInput";
import moment from "moment";
export default {
  props: {
    milestoneId: Number,
    dataSource: Object,
  },
  components: {
    "my-input": MyInput,
    "my-textarea": MyTextArea,
    "accessories-list": AccessoriesList,
    "accessories-drawer": AccessoriesDrawer,
  },
  data() {
    return {};
  },
  mounted() {
    if (this.milestoneId != -1) {
      this.milestoneGetAccessoriesListAction({
        employer_id: localStorage.getItem("employer_id"),
        milestone_id: this.milestoneId,
      })
        .then((res) => {
          for (let item of res) {
            item.create_time = moment(item.create_time).format(
              "YYYY/MM/DD HH:mm"
            );
          }
          this.dataSource.accessories = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });

      this.milestoneGetDetailAction({
        employer_id: localStorage.getItem("employer_id"),
        milestone_id: this.milestoneId,
      })
        .then((res) => {
          this.$emit("refreshInfo", res);
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    }
  },
  methods: {
    ...mapActions({
      milestoneGetAccessoriesListAction: "milestoneGetAccessoriesList",
      milestoneGetDetailAction: "milestoneGetDetail",
    }),
    add(acc) {
      acc.type = 1;
      this.dataSource.accessories.push(acc);
    },
    submit() {
      Promise.all([this.$refs.formName.doValidate()])
        .then(() => {
          this.$parent.$parent.showConfirm();
        })
        .catch((error) => {
          console.log(error);
          this.$Message.warning("表单中有错误项，请检查后修改并重新提交");
        });
    },
    /** 表单验证 */
    nameValidate(value) {
      return new Promise((resolve, reject) => {
        if (this.dataSource.name == "") {
          reject("请填写里程碑名称");
        } else {
          resolve();
        }
      });
    },
    upload() {
      this.$refs.accessoriesDrawer.open();
    },
  },
};
</script>

<style scoped>
.new-milestone-page-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.new-milestone-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}
</style>