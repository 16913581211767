<template>
  <div class="xls-table-container">
    <div class="xls-table-header-container">
      <span style="width:40%;text-align:center;font-weight:bold;">文件名</span>
      <span style="width:30%;text-align:center;font-weight:bold;">上传者</span>
      <span style="width:20%;text-align:center;font-weight:bold;">上传时间</span>
      <span style="width:10%;text-align:center;font-weight:bold;">操作</span>
    </div>
    <div class="xls-item-container">
      <xls-item
        v-for="item in dataSource"
        :key="item.id"
        :data-source="item"
      />
    </div>
  </div>
</template>

<script>
import XlsItem from '../Item/XlsItem'
export default {
  components:{
    "xls-item":XlsItem
  },
  props: {
    dataSource: Array,
  },
};
</script>

<style>
.xls-table-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 30px;
  padding-bottom: 10px;
}
.xls-table-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  min-height: 50px;
}
.xls-item-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
}
</style>