<template>
  <div class="accessory-list-root-container">
    <div class="accessory-list-header-container">
      <span
        class="accessory-item-header"
        :style="{ width: showType ? '20%' : '25%' }"
        >文件名</span
      >
      <span
        class="accessory-item-header"
        :style="{ width: showType ? '20%' : '25%' }"
        >备注名</span
      >
      <span
        class="accessory-item-header"
        :style="{ width: showType ? '20%' : '25%' }"
        >上传者</span
      >
      <span
        class="accessory-item-header"
        :style="{ width: showType ? '20%' : '25%' }"
        >上传时间</span
      >
      <span
        v-if="showType"
        class="accessory-item-header"
        :style="{ width: showType ? '20%' : '25%' }"
        >上传方式</span
      >
    </div>
    <div class="accessories-item-container">
      <accessories-row
        v-for="item in dataSource"
        :key="item.file_url"
        :data-source="item"
        :showType="showType"
      />
    </div>
  </div>
</template>

<script>
import AccessoriesRow from "../Item/AccessoriesRow";
export default {
  components: {
    "accessories-row": AccessoriesRow,
  },
  props: {
    dataSource: Array,
    showType: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style>
.accessory-list-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  padding-bottom: 10px;
}
.accessory-list-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  min-height: 50px;
}
.accessory-item-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
}
.accessory-item-header {
  text-align: center;
  font-weight: bold;
}
</style>