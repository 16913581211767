<template>
  <Modal v-model="isShowModal" footer-hide :closable="false">
    <div v-if="isShowModal" class="milestone-modal-root-container">
      <div class="milestone-top-container">
        <my-avatar v-if="isShowModal" :size="80" :name="dataSource.name" />
        <span style="font-size:23px;font-weight:bold;margin-top:20px;">{{dataSource.name}}</span>

        <fee style="margin-top:50px;" :amount="totalAmount" :primarySize="23" :secondarySize="20" />
        <span style="font-size:12px;margin-top:10px;">合计{{payList.length}}笔</span>
      </div>
      <avatar-list :member-list="payList" :max-count="7" style="margin-top:20px;" />
      <Button style="margin-top:40px;" type="primary"
        @click="$emit('confirm')">{{dataSource.milestone_id == -1?'确认创建':'确认更新'}}</Button>
      <Button style="margin-top:10px;" type="text" @click="isShowModal=false">返回</Button>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import AvatarList from "../../../components/List/AvatarList";
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
    "avatar-list": AvatarList,
  },
  data() {
    return {
      isShowModal: false,
      projectId: 0,
      payList: [],
    };
  },
  mounted() {
    this.projectId = this.$router.currentRoute.params.id;
    // this.projectGetBasicAction({
    //   employer_id: localStorage.getItem("employer_id"),
    //   project_id: this.projectId,
    // })
    //   .then((res) => {
    //     this.projectImgUrl = res.img_url;
    //     this.projectName = res.name;
    //   })
    //   .catch((error) => {
    //     this.$Message.error(error);
    //   });
  },
  computed: {
    totalAmount() {
      let total = 0;
      for (let item of this.payList) {
        total += item.amount;
      }
      return total;
    },
  },
  methods: {
    ...mapActions({
      // projectGetBasicAction: "projectGetBasic",
      milestoneGetExcelDataAction: "milestoneGetExcelData",
    }),
    open() {
      this.milestoneGetExcelDataAction({
        employer_id: localStorage.getItem("employer_id"),
        project_id: this.projectId,
        excel: this.dataSource.excel,
      })
        .then((res) => {
          this.payList = res.pay_list;
        })
        .catch((error) => {
          this.$Message.error(error.msg);
        });
      this.isShowModal = true;
    },
    close() {
      this.isShowModal = false;
    },
  },
};
</script>

<style scoped>
.milestone-modal-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}
.milestone-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f7f8;
  width: 400px;
  padding: 20px;
}
</style>